@font-face {
  font-family: 'Chakra Petch';
  font-weight: 700;
  src: url('./static/fonts/ChakraPetch-Bold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Chakra Petch';
  font-weight: 500;
  src: url('./static/fonts/ChakraPetch-Medium.woff') format('woff');
  font-display: swap;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  background: url("./assets/dashboard-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-attachment: fixed;
}




.main{
  height: 100%;
  width: 100%;
  padding: 40px 44px 40px 44px;
  box-sizing: border-box;
}

.header{
  width: 100%;
  border-bottom: 1px solid rgba(49, 221, 244, 0.19);
  padding-bottom: 26px;
}
.synthr-logo{
  height: 28px;
}


.aggregator-title{
  margin: 16px 0;
  padding-left: 10px;
  font-family: 'Chakra Petch';
  font-weight: 700;
  font-size: 21px;
  line-height: 128.1%;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}
.aggregator-sub-title {  
  margin: 0 0 0 16px;
  padding-left: 16px;
  padding-top: 16px;
  font-family: 'Chakra Petch';
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}
.aggregator-value-wrapper{
  position: relative;
  width: 100%;
  background: #071717;
  backdrop-filter: blur(5px);
  border-radius: 21px;
  z-index: 11;
}
.aggregator-value-container{
  position: relative;
  background: #081616;
  backdrop-filter: blur(5px);
  border-radius: 21px;
  padding: 24px 25px;
}
.table-right-side-circle{
  position: absolute;
  top: -95px;
  right: -45px;
  width: 335px;
  height: 205px;
  background: rgba(8, 220, 112, 0.17);
  filter: blur(105.5px);
  z-index: 0;
}
.aggregator-table{
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
}
table, th, td, tr{
  border-collapse: collapse;
} 
.aggregator-table th{
  font-family: 'Chakra Petch';
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #67807C;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: left;
  padding-bottom: 22px;
  border-bottom: 1px solid rgba(0, 244, 255, 0.12);
}
.aggregator-table tr{
  border-bottom: 1px solid rgba(0, 244, 255, 0.12);
}
.aggregator-table tr td{
  padding: 14px 0;
  font-size: 14px;
  letter-spacing: 0.02em;
  /* border-bottom: 1px solid rgba(0, 244, 255, 0.12); */
}
.aggregator-table tr th:first-child{
  width: 25%;
}
.total-supply-name{
  position: relative;
  display: flex;
  align-items: center;
}
.total-supply-name div{
  font-family: 'Chakra Petch';
  font-weight: 700;
  font-size: 15px;
  line-height: 150%;
  padding-left: 42px;
  color: #F8FFFF;
  order: 1;
  flex-grow: 0;
}
.chain-name {
  position: relative;
  align-items: center;
}
.chain-name div{
  font-family: 'Chakra Petch';
  font-weight: 700;
  font-size: 15px;
  line-height: 150%;
  padding-left: 42px;
  color: #F8FFFF;
  order: 1;
  flex-grow: 0;
}
.total-supply-name-2 div{
  font-family: 'Chakra Petch';
  font-weight: 700;
  font-size: 15px;
  line-height: 150%;
  /* padding-left: 42px; */
  color: #F8FFFF;
  order: 1;
  flex-grow: 0;
}
.pair-name {
  font-family: 'Chakra Petch';
  font-weight: 700;
  font-size: 15px;
  line-height: 150%;
  padding-left: 20px !important;
  color: #F8FFFF;
  order: 1;
  flex-grow: 0;
}
.token-icon{
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  height: 44px;
}
.token-icon2 {
  position: absolute;
  left: 18px;
  top: 25%;
  transform: translateY(-50%);
  height: 30px;
}
.values{
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 120%;
  color: #FFFFFF;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}
.wrong-value{
  color: #FF4949;
}
.success-value {
  color: rgb(24, 233, 24);
}
.values img{
  height: 15px;
  width: 15px;
  margin-right: 8px;
  flex-grow: 0;
}
.table-2{
  border-spacing: 0 8px;
  margin-bottom: 0;
}
.table-2 thead tr:first-child{
  visibility: hidden;
  opacity: 0;
}
.table-2 .total-supply-name div{
  padding-left: 0;
}
.table-1 tr:first-child{
  padding-left: 0px;
}
/* .table-2 tr td{
  border-bottom: 1px solid rgba(0, 244, 255, 0.12);
  border-top: 1px solid rgba(0, 244, 255, 0.12);
} */


/* nonce table */
.nonce-pair-name {
  font-family: 'Chakra Petch';
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #F8FFFF;
  order: 1;
  flex-grow: 0;
  text-transform: uppercase;
}

.d-flex {
  display: flex;
  align-items: center;
}
.d-clomumn {
  display: flex;
  flex-direction: column;
}
.svg-container {
  width: 18.3px;
  height: 17px;
  margin-right: 5px;
}
.svg-container:hover {
  cursor: pointer;
}
.justify-content-between {
  justify-content: space-between;
}

.wallet-input {
  min-width: 300px;
  height: 25px;
  margin-left: 10px;
  font-size: 12px;
}
.text {
  font-family: 'Chakra Petch';
  color: white;
  font-size: 14px;
}
.empty-card {
  background: #081616;
  min-height: 50px;
  padding: 20px;
  border-radius: 21px;
}
.pair-image {
  display: flex;
}
.header-link {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  text-decoration: none;
  margin: 0 20px;
  background-image: linear-gradient(110.28deg, #FFFFFF -0.66%, #FFFFFF 98.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent !important;
}
.header-link:hover {
  background-image: linear-gradient(110.28deg, #2FF6F7 -0.66%, #08DD70 98.48%);
}
.active-link {
  background-image: linear-gradient(110.28deg, #2FF6F7 -0.66%, #08DD70 98.48%);
}
.empty {
  width: 100px;
}
.wallet-connect-btn {
  border: none;
  height: 55px;
  padding: 0px 32px;
  background: linear-gradient(110.28deg, #2FF6F7 -0.66%, #08DD70 98.48%);
  border-radius: 11px;
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #00524D;
}
.wallet-connect-btn:hover {
  cursor: pointer;
  color: #FFF;
}
.retry-button {
  position: relative;
  border: none;
  height: 41px;
  background: transparent;
  border-radius: 12px;
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  
  color: #FFAE50;
}
.retry-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  padding: 1px;
  background: linear-gradient(180deg, #FFAE50 0%, rgba(255, 174, 80, 0) 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-image: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
.retry-button:hover {
  cursor: pointer;
  color: white;
}
.retry-button:disabled {
  cursor: not-allowed;
  color: grey;
}
.check-box {
  margin-right: 5px;
}
.aggregator-title label{
  font-size: 12px;
  font-weight: 500;
}

@media(max-width: 1400px){
  .aggregator-title{
    font-size: 20px;
  }
  .aggregator-table{
    margin-bottom: 30px;
  }
  .aggregator-table th{
    font-size: 12px;
  }
  .values{
    font-size: 14px;
    cursor: pointer;
  }
  .aggregator-table tr th:first-child{
    width: 24%;
  }
  .table-2{
    margin-bottom: 0;
  }
}
.align-items-center{
  align-items: center;
}
.mt-1 {
  margin-top: 10px;
}
.sh_wrapper th{
  /* text-align: center; */
  padding-left: 20px;
  width: 100%;
}
.sh_wrapper tr td{
  padding: 14px 0 14px 20px;
}
.wrapl {
  width: 100%;
    display: flex;
    padding: 5px;
}
span.weaplname {
  width: 90px;
  text-align: center;
}
span.weaplvalue {
  width: 200px;
  text-align: center;
}
.showBtn{
  width: 80px;
    height: 40px;
    color: #000;
    z-index: 10000;
    background: linear-gradient(110.28deg, #2FF6F7 -0.66%, #08DD70 98.48%);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}
.button-wrapper{
  display: flex;
    justify-content: center;
}
.sbtl {
  margin-bottom: 10px;
}
.lightChainTd{
  display: flex;
  justify-content: space-between;
}
th.tl {
  width: 24%;
}